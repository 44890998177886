<template>
  <v-list>
    <DateRangePicker v-model="searchTime" />
    <v-list-item>
      <v-list-item-content>
        <v-row>
          <v-col cols="2">
            <b>Order Date</b>
          </v-col>
          <v-col cols="2">
            <b>Sid</b>
          </v-col>
          <v-col cols="2">
            <b>Total Amount</b>
          </v-col>
          <v-col cols="1">
            <b>Order Type</b>
          </v-col>
          <v-col cols="1">
            <b>Payment Method</b>
          </v-col>
          <!-- <v-col cols="1">
            <b>Payment Status</b>
          </v-col> -->
          <v-col cols="1">
            <b>Order Status</b>
          </v-col>
          <v-col cols="2">
            <b>Store</b>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
        <v-divider />
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-for="item in list" :key="item._id">
      <v-list-item-content>
        <v-row>
          <v-col cols="2">{{ item.createdAt | moment('YYYY/MM/DD hh:mm') }}</v-col>
          <v-col cols="2">{{ item.sid }} </v-col>
          <v-col cols="2">HK$ {{ item._amount | toThousandFilter }}</v-col>
          <v-col cols="1">{{ get(item, 'orderType', '-') }}</v-col>
          <v-col cols="1">{{ get(item, 'payment_method[0].mode', '-') }}</v-col>
          <!-- <v-col cols="1">{{ item.paymentStatus }}</v-col> -->
          <v-col cols="1"
            >{{ item.status | status }}
            <span style="color: red">
              {{ item.isVoided ? '(Voided)' : '' }}
            </span></v-col
          >
          <v-col cols="2">{{ get(item, 'store') }}</v-col>
          <v-col cols="1"
            ><v-btn v-if="item.relatedTransactions.length > 0" @click="show(item)"
              >Show Detail</v-btn
            ></v-col
          >
        </v-row>
        <v-divider />
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="!list || !list.length" class="text-center">
      <v-row>
        <v-col> No Data </v-col>
      </v-row>
    </v-list-item>
    <v-pagination class="mt-4" v-model="pageVal" :total-visible="10" :length="data.pages || 1" />
  </v-list>
</template>

<script>
import api from '@/api';
import { crudList } from '@/api/crud';
import { RESTFUL } from '@/data/constants';
import { get, merge } from 'lodash';
import { getStores } from '@/api/store';
import moment from 'moment';
import DateRangePicker from '@/components/DateRangePicker';

export default {
  name: 'IdentityPhysicalProductOrderList',
  props: {
    id: {},
    isMaster: Boolean,
    isVoided: Boolean,
  },
  components: {
    DateRangePicker,
  },
  data() {
    return {
      list: [],
      data: {},
      page: 1,
      storeList: [],
      searchTime: {
        // start: moment().startOf('d').format('YYYY-MM-DD'),
        // end: moment().startOf('d').format('YYYY-MM-DD'),
      },
    };
  },
  computed: {
    pageVal: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.reload();
      },
    },
    restfulURL: () => {
      const posConfigUrl = `${process.env.VUE_APP_POS_GATEWAY}`;
      return posConfigUrl + `/orders/admin-panel`;
    },
  },
  watch: {
    id: {
      immediate: true,
      async handler(v) {
        if (v) {
          await this.loadStoreList();
          // await this.reload();
        }
      },
    },
    searchTime: {
      immediate: true,
      deep: true,
      async handler(v) {
        if (v.start && v.end) await this.reload();
      },
    },
  },
  methods: {
    get,
    moment,
    show(item) {
      const firstTransaction = item?.relatedTransactions[0];
      const id = firstTransaction._id;
      if (id) this.$emit('show', id);
    },
    async reload() {
      try {
        this.data = (
          await api.get(this.restfulURL, {
            params: merge({
              page: this.page,
              limit: 10,
              sort: '-createdAt',
              paginate: true,
              qWorkspace: '2ac705576a2e',
              client: this.id,
              startDate: new Date(
                moment(this.searchTime.start).startOf('day').toDate(),
              ).toUTCString(),
              endDate: new Date(moment(this.searchTime.end).endOf('day').toDate()).toUTCString(),
            }),
            headers: { Authorization: 'posfletrixlongtimetoken' },
          })
        ).data;

        this.list = this.data.docs.map(x => {
          const storeInfo = this.storeList.find(store => store._id.toString() == x.store);
          if (storeInfo) return { ...x, store: storeInfo.name };
          else return { ...x, store: 'not found store' };
        });
      } catch (e) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: e,
        });
      }
    },
    async loadStoreList() {
      const storeData = await getStores(api, { limit: -1 });
      this.storeList = storeData.docs.map(x => x);
    },
  },
};
</script>

<style scoped></style>
