<template>
  <v-dialog
    v-model="valueProxy"
    width="60%"
    max-width="100vw"
    @create="reload"
    @update="reload"
    @delete="reload"
  >
    <ValidationObserver ref="form" v-slot="{ invalid }">
      <v-card min-height="250">
        <v-card-title>
          <span class="mainTitle" style="font-size: 20px"> {{ 'Product' }} </span>
          <v-spacer />
          <v-btn icon :disabled="loading" @click="valueProxy = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Product Name (HK)" />
                <ValidationProvider v-slot="{ errors }" name="Product Name (HK)" rules="required">
                  <v-text-field
                    v-model="localizedName['zh-hk']"
                    outlined
                    label="Product Name (HK)"
                    :disabled="loading"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Product Name (EN)" />
                <ValidationProvider v-slot="{ errors }" name="Product Name (EN)" rules="required">
                  <v-text-field
                    outlined
                    v-model="localizedName['en']"
                    label="Product Name (EN)"
                    :disabled="loading"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Listed Price" />
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Listed Price"
                  :rules="{ required: true, min_value: 0 }"
                >
                  <v-text-field
                    outlined
                    type="number"
                    v-model="model.listedPrice"
                    label="Listed Price"
                    min="0"
                    :disabled="loading"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Discount Price" />
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Discount Price"
                  :rules="{
                    required: true,
                    min_value: 0,
                  }"
                >
                  <v-text-field
                    outlined
                    type="number"
                    v-model="model.discountedPrice"
                    label="Discount Price"
                    min="0"
                    :disabled="loading"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Cost" />
                <ValidationProvider v-slot="{ errors }" name="Cost" :rules="{ min_value: 0 }">
                  <v-text-field
                    v-model="model.cost"
                    label="Cost"
                    min="0"
                    outlined
                    type="number"
                    :disabled="loading"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Product Category" />
                <ValidationProvider v-slot="{ errors }" name="Product Category" rules="required">
                  <v-select
                    v-model="model.category"
                    :items="productCategories"
                    class="pl-2"
                    item-text="title"
                    item-value="_id"
                    label="Category"
                    outlined
                    style="max-width: 300px"
                    :disabled="loading"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <!-- <v-col cols="12" md="6">
                <custom-label item-field-name="Stock" />
                <ValidationProvider v-slot="{ errors }" name="Stock" rules="required">
                  <v-text-field
                    outlined
                    type="number"
                    v-model="model.stock"
                    label="Stock Number"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col> -->
              <v-col cols="12" md="6">
                <custom-label item-field-name="Description" />
                <ValidationProvider v-slot="{ errors }" name="Description">
                  <v-textarea
                    outlined
                    v-model="model.description"
                    label="Description"
                    :disabled="loading"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Product Supplier" v-slot="{ errors }" />
                <v-select
                  v-model="model.supplier"
                  :items="productSuppliers"
                  class="pl-2"
                  item-text="name"
                  item-value="_id"
                  label="Supplier"
                  style="max-width: 300px"
                  outlined
                />
              </v-col>
              <v-col cols="12">
                <div>
                  <div class="d-flex">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="barcode"
                      :rules="{ uniqueBarcodes: [computedBarcodeList] }"
                    >
                      <v-text-field
                        v-model="model.barcode[0]"
                        :error-messages="errors"
                        flat
                        label="Barcode"
                        outlined
                        :disabled="loading"
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </div>
                  <div v-if="model && model.barcode && model.barcode.length > 1">
                    <template
                      v-for="(itemBarcode, index) in model &&
                      model.barcode &&
                      model.barcode.slice(1)"
                    >
                      <div class="d-flex align-baseline">
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="barcode"
                          :rules="{ required: true, uniqueBarcodes: [computedBarcodeList] }"
                        >
                          <v-text-field
                            v-model="model.barcode[index + 1]"
                            :error-messages="errors"
                            flat
                            label="Barcode"
                            outlined
                            :disabled="loading"
                          >
                          </v-text-field>
                        </ValidationProvider>
                        <v-btn icon :disabled="loading" @click="deleteBarcode(index + 1)">
                          <v-icon>mdi-minus-circle-outline </v-icon>
                        </v-btn>
                      </div>
                    </template>
                  </div>
                  <v-btn color="primary" :disabled="loading" @click="addBarcode">
                    <v-icon color="white">mdi-plus</v-icon>
                  </v-btn>
                </div>
                <small class="error--text">
                  Barcode(s) will not be able to edit or delete once saved.
                </small>
              </v-col>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Featured Image" />
                <ValidationProvider v-slot="{ errors }" name="Featured Image">
                  <v-file-input
                    outlined
                    v-model="model.featuredImage"
                    label="Image"
                    :error-messages="errors"
                    accept="image/jpeg,image/png"
                    :loading="updateFeaturedImageLoading"
                    :disabled="updateFeaturedImageLoading"
                  />
                </ValidationProvider>
              </v-col>
              <!--              <v-col cols="12" md="6">-->
              <!--                <custom-label item-field-name="Other Images" />-->
              <!--                <ValidationProvider v-slot="{ errors }" name="Other Images">-->
              <!--                  <v-file-input-->
              <!--                    outlined-->
              <!--                    show-size-->
              <!--                    counter-->
              <!--                    multiple-->
              <!--                    v-model="model.images"-->
              <!--                    label="Images"-->
              <!--                    :error-messages="errors"-->
              <!--                    accept="image/jpeg,image/png"-->
              <!--                  />-->
              <!--                </ValidationProvider>-->
              <!--              </v-col>-->
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn :disabled="loading" @click="doClose()">Close</v-btn>
          <v-btn
            color="primary"
            @click="debouncePromptCreate"
            :disabled="invalid"
            v-if="isCreate"
            :loading="loading"
          >
            Create
          </v-btn>
          <v-btn
            color="primary"
            @click="debouncePromptUpdate"
            :disabled="invalid"
            v-if="!isCreate"
            :loading="loading"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import CustomLabel from '@/components/customFormLabel/customLabel';
import editDialogMixin from '@/services/editDialogMixin';
import { uploadMultipleAttachment, uploadSingleAttachment } from '@/api/attachment';
import api from '@/api';
import { getBarcodeList } from '@/api/physicalProduct';
import { crudCreate } from '@/api/crud';
import { RESTFUL } from '@/data/constants';
import ApiError from '@/api/ApiError';
import { ValidationProvider, Validator } from 'vee-validate';
import { debounce } from 'lodash';

Validator.extend('uniqueBarcodes', {
  getMessage: (field, params, data) => {
    return 'Barcode must be unique.';
  },
  validate: (value, [barcodes]) => {
    if (value) return barcodes?.filter(barcode => barcode === value)?.length === 1;
    else return true;
  },
});

export default {
  name: 'ProductEditDialog',
  components: { CustomLabel, ValidationProvider },
  mixins: [editDialogMixin],
  props: {
    title: {},
    productCategories: [],
    productSuppliers: [],
  },
  data() {
    return {
      updateFeaturedImageLoading: false,
      localizedName: {},
      barcodeList: [],
      debouncePromptCreate: debounce(this.promptCreate, 500),
      debouncePromptUpdate: debounce(this.promptUpdate, 500),
    };
  },
  watch: {
    localizedName: {
      deep: true,
      handler(v) {
        if (typeof v === 'object' && Object.keys(v)?.length > 0) {
          if (v?.['zh-hk']) this.localizedName['zh-cn'] = v?.['zh-hk'];
          this.model.name = JSON.stringify(v);
        } else {
          this.model.name = '';
        }
      },
    },
    value(v) {
      // clear the data when close the dialog
      if (!v) {
        this.localizedName = {};
      }
    },
  },
  mounted() {
    this.reload();
    this.getBarcodeList();
  },
  computed: {
    computedCommissionRate: {
      // rule {decimal: 1} is required in this logic
      get() {
        return (Math.round(this.model?.commissionRate * 10000) / 100).toString();
      },
      set(v) {
        this.$set(this.model, 'commissionRate', Math.round(v * 100) / 10000);
      },
    },
    computedBarcodeList() {
      return this.barcodeList.concat(this.model.barcode);
    },
  },
  methods: {
    async reload() {
      this.model = {
        stock: 1,
        barcode: [],
      };
    },
    async getBarcodeList() {
      this.barcodeList = await getBarcodeList(api);
    },
    async promptCreate() {
      if (!(await this.$refs.form.validate())) {
        return;
      }
      try {
        this.loading = true;
        if (this.model?.featuredImage) {
          this.updateFeaturedImageLoading = true;
          const attachmentId = await uploadSingleAttachment(api, this.model.featuredImage).finally(
            () => {
              this.updateFeaturedImageLoading = false;
            },
          );
          this.model.featuredImage = attachmentId._id;
        }
        if (this.model?.images?.length > 0) {
          const attachmentIds = await uploadMultipleAttachment(api, this.model.images);
          this.model.images = attachmentIds;
        }
        const model = await crudCreate(api, RESTFUL.physicalProducts.create, this.model);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Created',
          type: 'success',
          color: 'success',
        });
        this.$emit('create', model);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.loading = false;
      }
    },
    handleError(err) {
      const ae = ApiError.wrap(err);
      this.$store.dispatch('alert/updateAlertMessage', {
        msg: ae.message,
      });
    },
    addBarcode() {
      this.model.barcode = this.model.barcode.length === 0 ? [null] : this.model.barcode;
      this.model.barcode.push(null);
    },
    deleteBarcode(index) {
      this.model.barcode.splice(index, 1);
    },
  },
};
</script>

<style scoped></style>
