<template>
  <div>
    <ListView
      title="Transfer Problem"
      @reload="reload"
      @create="actionCreate"
      @navDetails="() => false"
      :search.sync="search"
      :data="data"
      :header="selectedTab ? header : confirmedTabHeader"
      :enableCreate="false"
      :page.sync="page"
      :loading="isLoading"
    >
      <template #searchBox>
        <div class="d-flex" style="gap: 10px">
          <div class="d-flex align-center selectListCol">
            <v-menu
              v-model="startTimeDialog"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedStart"
                  label="From"
                  prepend-icon="mdi-calendar"
                  style="min-width: max-content"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="selectedStart"
                @input="startTimeDialog = false"
                header-color="primary"
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="d-flex align-center selectListCol">
            <v-menu
              v-model="endTimeDialog"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedEnd"
                  label="To"
                  prepend-icon="mdi-calendar"
                  style="max-width: max-content"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                header-color="primary"
                v-model="selectedEnd"
                @input="endTimeDialog = false"
              >
              </v-date-picker>
            </v-menu>
          </div>
          <template v-if="selectedTab">
            <v-select
              v-model="filterSentWarehouse"
              :items="warehouseList"
              item-text="name"
              item-value="_id"
              label="Sent Warehouse"
              style="max-width: 300px"
              outlined
              clearable
            />
            <v-select
              v-model="filterReceivedWarehouse"
              :items="warehouseList"
              item-text="name"
              item-value="_id"
              label="Received Warehouse"
              style="max-width: 300px"
              outlined
              clearable
            />
          </template>
          <!-- <template v-if="!selectedTab"> </template> -->
        </div>
      </template>

      <template #filter>
        <div class="d-flex" style="border-bottom: 1px solid #e0e0e0">
          <v-btn-toggle v-model="selectedTab" color="primary" tile>
            <v-btn v-for="tab in tabs" :value="tab.value" :key="`tab_${tab.value}`"
              >{{ tab.text }}
            </v-btn>
          </v-btn-toggle>
        </div>
      </template>
      <template v-slot:item.product.name="{ item }">
        {{ parseLocalizedString(item.product.name)['zh-hk'] }}
      </template>
      <template v-slot:item.updatedAt="{ item }">
        {{ item.updatedAt | moment }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          v-if="get(item, 'needToFixQtyDifference', false)"
          color="primary"
          class="text-bold"
          @click.stop="handleOnClick(item._id)"
        >
          Adjust
        </v-btn>
      </template>
      <template v-slot:item.metadata.TransferProblemConfirmedAction="{ item }">
        {{
          get(item, 'metadata.TransferProblemConfirmedAction', '-')
            | translateOptions(TransferProblemConfirmedActionOptions)
        }}
      </template>
      <template v-slot:item.addedTo="{ item }">
        {{ computeAddedLostStockTo(item) || '-' }}
      </template>
    </ListView>
    <!-- Adjust Dialog -->
    <v-dialog v-model="adjustDialogRef" width="600px">
      <v-card>
        <v-card-title class="text-h5">Add Lost Stock to Warehouse</v-card-title>
        <ValidationObserver ref="form" v-slot="{ valid }">
          <v-form>
            <v-card-text>
              <custom-label item-field-name="Reason" />
              <ValidationProvider v-slot="{ errors }" name="Reason" rules="required">
                <v-select
                  v-model="updateReason"
                  outlined
                  :items="productActivityLogUpdateReasonOptions"
                  :error="!!errors.length"
                  :error-messages="errors"
                ></v-select>
              </ValidationProvider>
              <template v-if="updateReason === ProductActivityLogUpdateReason.Other">
                <ValidationProvider v-slot="{ errors }" name="Other Reason" rules="required">
                  <v-text-field
                    v-model="remarks"
                    label="Other Reason"
                    outlined
                    :error="!!errors.length"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </template>
              <div class="d-flex justify-space-between">
                <v-btn
                  color="primary"
                  @click="
                    updateStockWithDifferences('from', TransferProblemConfirmedAction.AddedToSender)
                  "
                >
                  Sender
                </v-btn>
                <v-btn width="fit-content" color="primary" @click="markConfirmed()">
                  Mark Confirmed Only
                </v-btn>
                <v-btn
                  color="primary"
                  @click="
                    updateStockWithDifferences('to', TransferProblemConfirmedAction.AddedToReceiver)
                  "
                >
                  Receiver
                </v-btn>
              </div>
            </v-card-text>
          </v-form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '@/api';
import { mapGetters } from 'vuex';
import CustomLabel from '@/components/customFormLabel/customLabel';
import { crudRead, crudList, crudUpdate } from '@/api/crud';
import { assignStoreUsers } from '@/api/storeUser';
import { RESTFUL, TransferProblemConfirmedAction } from '@/data/constants';
import ListView from '@/components/ListView';
import listViewPageMixin from '@/services/listViewPageMixin';
import { getOid, parseLocalizedString } from '@/services/utils';
import { ProductActivityLogUpdateReason } from '@/data/constants';
import {
  productActivityLogUpdateReasonOptions,
  TransferProblemConfirmedActionOptions,
} from '@/data/optionsConstants';
import moment from 'moment';
import { get } from 'lodash';
import CustomDialog from '@/components/CustomDialog.vue';
import { fixDifferences } from '@/api/productActivityLog';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { translateOptions } from '@/services/filters';

export default {
  name: 'StoreUserList',
  components: { CustomLabel, ListView, CustomDialog, ValidationProvider, ValidationObserver },
  mixins: [listViewPageMixin],
  data() {
    return {
      TransferProblemConfirmedAction,
      TransferProblemConfirmedActionOptions,
      ProductActivityLogUpdateReason,
      productActivityLogUpdateReasonOptions,
      header: [
        { text: 'Updated At', value: 'updatedAt' },
        { text: 'Updated By', value: 'updatedBy.name' },
        { text: 'Sent Warehouse', value: '_StockFlow.from.warehouseName' },
        { text: 'Received Warehouse', value: '_StockFlow.to.warehouseName' },
        { text: 'Product Name(HK)', value: 'product.name' },
        { text: 'Quantity Lost', value: 'metadata.lost_qty' },
        { text: '', value: 'action' },
      ],
      confirmedTabHeader: [
        { text: 'Updated At', value: 'updatedAt' },
        { text: 'Updated By', value: 'updatedBy.name' },
        { text: 'Product Name(HK)', value: 'product.name' },
        { text: 'Quantity Lost', value: 'metadata.lost_qty' },
        { text: 'Confirm Action', value: 'metadata.TransferProblemConfirmedAction' },
        { text: 'Added Lost Stock To', value: 'addedTo' },
      ],
      data: [],
      search: '',
      populate: [
        { path: 'warehouse', select: ['name'] },
        { path: 'updatedBy' },
        { path: 'product', select: ['name'] },
      ],
      warehouseList: [],
      filterSentWarehouse: null,
      filterReceivedWarehouse: null,
      filterReason: null,
      startTimeDialog: false,
      endTimeDialog: false,
      searchTime: {
        start: null,
        end: null,
      },
      adjustDialogRef: false,
      selectedDataId: '',
      updateReason: '',
      remarks: '',
      selectedTab: true,
      tabs: [
        { text: 'Pending Confirm', value: true },
        { text: 'Confirmed', value: false },
      ],
    };
  },
  mounted() {
    this.reloadWarehouse();
  },
  watch: {
    updateReason() {
      this.remarks = '';
    },
    adjustDialogRef() {
      this.updateReason = '';
      this.remarks = '';
    },
  },
  computed: {
    ...mapGetters('auth', ['role', 'isAdmin', 'isMaster']),
    // isNew() {
    //   return this.id === 'new';
    // },
    restfulURL() {
      return RESTFUL.productActivityLog.read;
    },
    filter() {
      const out = {
        needToFixQtyDifference: this.selectedTab,
      };

      if (this.filterSentWarehouse) {
        out['form_warehouse'] = getOid(this.filterSentWarehouse);
      }
      if (this.filterSentWarehouse) {
        out['to_warehouse'] = getOid(this.filterReceivedWarehouse);
      }
      if (this.searchTime.start && this.searchTime.end) {
        out['created_at'] = {
          ...(this.searchTime.start ? { $gte: moment(this.searchTime.start).startOf('day') } : {}),
          ...(this.searchTime.end ? { $lte: moment(this.searchTime.end).endOf('day') } : {}),
        };
      }
      return out;
    },
    selectedStart: {
      get() {
        if (this.searchTime.start != null)
          return moment(this.searchTime.start).format('YYYY-MM-DD');
        return null;
      },
      set(v) {
        this.searchTime.start = +moment(v).startOf('day');
      },
    },
    selectedEnd: {
      get() {
        if (this.searchTime.end != null) return moment(this.searchTime.end).format('YYYY-MM-DD');
        return null;
      },
      set(v) {
        this.searchTime.end = +moment(v).endOf('day');
      },
    },
    selectedData() {
      return this.data?.docs?.find(x => x._id === this.selectedDataId);
    },
  },
  methods: {
    get,
    parseLocalizedString,
    fixDifferences,
    translateOptions,
    async reloadWarehouse() {
      this.warehouseList =
        (
          await crudList(api, RESTFUL.warehouses.list, {
            limit: 1000,
          })
        )?.docs || [];
    },
    handleOnClick(id) {
      this.adjustDialogRef = true;
      this.selectedDataId = id;
    },
    async updateStockWithDifferences(warehouse, action) {
      if (!(await this.$refs.form?.validate?.())) return;

      const warehouseData =
        warehouse === 'from' ? this.selectedData._StockFlow.from : this.selectedData._StockFlow.to;
      const warehouseId = getOid(warehouseData.warehouseId);

      const confirm = await this.$root.$confirm.open(
        'Confirm',
        `Confirm increasing the stock by ${this.selectedData?.metadata?.lost_qty} to ${
          parseLocalizedString(this.selectedData.product.name)?.['zh-hk']
        } in ${warehouseData.warehouseName} ?`,
        {
          color: 'primary',
        },
        'OK',
      );
      if (!confirm) return;

      try {
        await fixDifferences(api, this.selectedDataId, {
          warehouseId,
          action,
          updateReason: this.updateReason,
          remarks: this.remarks,
        });
      } catch (error) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: error.message,
          type: 'error',
          color: 'error',
        });
        return;
      }

      await this.$store.dispatch('alert/updateAlertMessage', {
        msg: 'Confirmed',
        type: 'success',
        color: 'success',
      });
      this.reload();
      this.adjustDialogRef = false;
    },
    async markConfirmed() {
      if (!(await this.$refs.form?.validate?.())) return;

      if (!this.selectedData?.needToFixQtyDifference) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Already confirmed',
          type: 'error',
          color: 'error',
        });
        return;
      }

      const confirm = await this.$root.$confirm.open(
        'Confirm',
        `Confirm to not add back the difference, and mark it as confirmed?`,
        {
          color: 'secondary',
        },
        'OK',
      );
      if (!confirm) return;

      try {
        await crudUpdate(api, RESTFUL.productActivityLog.update, this.selectedDataId, {
          needToFixQtyDifference: false,
          metadata: {
            ...this.selectedData.metadata,
            TransferProblemConfirmedAction: TransferProblemConfirmedAction.OnlyConfirmed,
          },
        });
      } catch (error) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: error.message,
          type: 'error',
          color: 'error',
        });
        return;
      }

      await this.$store.dispatch('alert/updateAlertMessage', {
        msg: 'Confirmed',
        type: 'success',
        color: 'success',
      });
      this.reload();
      this.adjustDialogRef = false;
    },
    computeAddedLostStockTo(data) {
      if (!data?.metadata?.TransferProblemConfirmedAction) return null;

      if (
        data?.metadata?.TransferProblemConfirmedAction ===
        TransferProblemConfirmedAction.AddedToSender
      )
        return data?._StockFlow.from.warehouseName;
      if (
        data?.metadata?.TransferProblemConfirmedAction ===
        TransferProblemConfirmedAction.AddedToReceiver
      )
        return data?._StockFlow.to.warehouseName;
      return null;
    },
  },
};
</script>
