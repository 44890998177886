<template>
  <ListView
    :title="title"
    @reload="reload"
    @create="actionCreate"
    @navDetails="actionDetails"
    :enable-create="!isMaster"
    :search.sync="search"
    :data="data"
    :limit="limit"
    :page.sync="page"
    :header="computedHeader"
    :loading="isLoading"
  >
    <template v-slot:searchBox="{ ctx }">
      <v-text-field solo filled prepend-inner-icon="mdi-magnify" v-model="ctx.searchVal">
        <template v-slot:append>
          <v-icon @click="() => (qrcodeDialog = true)">mdi-qrcode-scan</v-icon>
        </template>
      </v-text-field>
    </template>
    <template v-if="selectedRole === 'toca.client'" v-slot:filterRight>
      <v-select v-model="birthday" :items="month" clearable flat label="Birthday Month" outlined />
    </template>
    <template v-slot:card="{ cardContent }">
      <Card :content="cardContent" class="clickable" @click.native="actionDetails(cardContent)" />
    </template>
    <template v-slot:item.phone="{ item }">
      {{ displayPhone(item.phone) || '-' }}
    </template>
    <template v-slot:item.enabled="{ item }">
      {{ item.enabled ? 'Active' : 'Inactive' }}
    </template>
    <template v-slot:item._VIP_activated="{ item }">
      {{ get(item, '_VIP_activated.VIPProduct.name', '-') | parseAndLocalize }}
    </template>
    <template v-if="selectedRole === 'toca.cashier'" v-slot:filterRight>
      <div style="width: 200px">
        <v-select
          v-model="selectedStatus"
          :items="profileStatuses"
          item-value="value"
          item-text="text"
          clearable
          flat
          label="Status"
          outlined
        />
      </div>
    </template>
    <IdentityEditDialog
      v-model="editDialog"
      :id="editId"
      :title="title"
      :role="selectedRole"
      :selectedManagement="selectedManagement"
      @create="reload"
      @update="reload"
      @delete="reload"
    />
    <v-dialog v-model="qrcodeDialog" fullscreen>
      <qrcode-stream @decode="onDecode" @init="onInit">
        <div class="d-flex">
          <v-spacer />
          <v-btn icon small @click="() => (qrcodeDialog = false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </qrcode-stream>
    </v-dialog>
  </ListView>
</template>

<script>
import listViewPageMixin from '@/services/listViewPageMixin';
import Card from '@/components/Identity/IdentityCard';
import IdentityEditDialog from '@/components/Identity/IdentityEditDialog';
import { mapGetters } from 'vuex';
import { RESTFUL, roles } from '@/data/constants';
import { decapitalize, displayPhone, translateOptions } from '@/services/filters';
import { month, roleOptions, managementOptions } from '@/data/optionsConstants';
import { ProfileStatus } from '@/api/auth/membership';
import { getMatchedStores } from '@/api/storeUser';
import { get } from 'lodash';
import { QrcodeStream } from 'vue-qrcode-reader';
import api from '@/api';

export default {
  name: 'profiles',
  props: {
    selectedRole: {},
    selectedManagement: {},
  },
  mixins: [listViewPageMixin],
  components: { IdentityEditDialog, Card, QrcodeStream },
  data() {
    return {
      month: month,
      header: [
        { text: 'Name', value: 'name', width: '280px' },
        { text: 'SID', value: 'sid', width: '200px' },
      ],
      birthday: null,
      selectedStatus: ProfileStatus.accepted,
      populate: [{ path: '_VIP_activated', populate: ['VIPProduct'] }],
      qrcodeDialog: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['role', 'isMaster']),
    restfulURL() {
      let out = RESTFUL.profile.list;
      if (!this.isMaster) {
        if (this.selectedRole === 'toca.client') {
          out = RESTFUL.clientProfile.list;
        }
        if (this.selectedRole === 'toca.master') {
          out = RESTFUL.masterProfile.list;
        }
      } else {
        if (this.selectedRole === 'toca.client') {
          out = RESTFUL.clientProfile.masterList;
        }
      }
      return out;
    },
    computedHeader() {
      const dynamicHeader = {
        'toca.client': [
          { text: 'Phone', value: 'phone', width: '200px' },
          // { text: 'Credit', value: 'creditBalance', width: '200px' },
          // { text: 'VIP Product', value: '_VIP_activated', width: '300px' },
        ],
        'toca.cashier': [
          { text: 'Phone', value: 'phone', width: '200px' },
          { text: 'Stores', value: 'stores', width: '200px' },
          { text: 'Status', value: 'enabled', width: '150px' },
        ],
        admin: [{ text: 'Phone', value: 'phone', width: '200px' }],
        coach: [{ text: 'Phone', value: 'phone', width: '200px' }],
        facility: [
          { text: 'Servicing Capacity', value: 'servicingCapacity', width: '200px' },
          { text: 'Booking Capacity', value: 'bookingCapacity', width: '200px' },
        ],
      };
      return [
        ...this.header,
        ...(dynamicHeader[this.selectedRole] || []),
        ...(dynamicHeader[this.selectedManagement] || []),
      ];
    },
    filter() {
      const query = this.selectedManagement
        ? this.selectedManagement === 'facility'
          ? {
              isFacility: 'true',
            }
          : {
              isFacility: { $ne: true },
            }
        : {
            birthday:
              this.birthday && this.selectedRole === 'toca.client' ? this.birthday : undefined,
          };
      return {
        ...query,
        role: this.selectedRole || null,
        status: this.selectedStatus ? this.selectedStatus : undefined,
      };
    },
    mergeParams() {
      return {};
    },
    title() {
      return this.selectedRole === 'toca.master'
        ? `Management-${translateOptions(this.selectedManagement, managementOptions)}`
        : this.selectedRole
        ? `Accounts-${translateOptions(this.selectedRole, roleOptions)}`
        : `Accounts`;
    },
    profileStatuses() {
      return [
        { text: 'Active', value: ProfileStatus.accepted },
        { text: 'Inactive', value: ProfileStatus.rejected },
      ];
    },
  },
  watch: {
    data: {
      immediate: true,
      async handler(v) {
        if (v.docs.length) {
          const profileIds = [...new Set(v?.docs?.map(x => x._id))];
          const fetchedData = await getMatchedStores(api, {
            profileIds,
          });

          const prepData = this.data.docs.map(d => {
            return { ...d, stores: fetchedData[d._id]?.map(det => det.name)?.join(', ') };
          });

          // MERGE USER INFO WITH RELATED STORES IF ADMIN
          this.$set(this.data, 'docs', prepData);
        }
      },
    },
  },
  methods: {
    get,
    displayPhone,
    async onDecode(uuid) {
      this.search = uuid;
      this.qrcodeDialog = false;
    },
    async onInit(promise) {
      // show loading indicator
      this.isLoading = true;
      try {
        await promise;
        // successfully initialized
      } catch (error) {
        this.qrcodeDialog = false;
        let msg;
        if (error.name === 'NotAllowedError') {
          msg = 'user denied camera access permission';
        } else if (error.name === 'NotFoundError') {
          msg = 'no suitable camera device installed';
        } else if (error.name === 'NotSupportedError') {
          msg = 'page is not served over HTTPS (or localhost)';
        } else if (error.name === 'NotReadableError') {
          msg = 'maybe camera is already in use';
        } else if (error.name === 'OverconstrainedError') {
          msg = 'did you request the front camera although there is none?';
        } else if (error.name === 'StreamApiNotSupportedError') {
          msg = 'browser seems to be lacking features';
        }
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg,
          type: 'error',
          color: 'error',
        });
      } finally {
        // hide loading indicator
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.selectedItem ::v-deep .v-select__selection--comma {
  margin-bottom: -25px;
}

.role {
  top: 10px;
  position: absolute;
  left: 24px;
  color: var(--v-success-base);
}
</style>
