<template>
  <v-dialog
    v-model="valueProxy"
    width="90vw"
    :max-width="maxWidth"
    @create="reload"
    @update="reload"
    @delete="reload"
  >
    <ValidationObserver ref="form" v-slot="{ valid }">
      <v-card class="d-flex flex-column" min-height="70vh">
        <v-card-title>
          <span class="mainTitle" style="font-size: 20px">
            {{ title }}
          </span>
          <v-spacer />
          <v-btn icon @click="valueProxy = false">
            <v-icon> mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="flex-1">
          <v-form>
            <v-row>
              <v-col cols="12">
                <custom-label item-field-name="Name" />
                <ValidationProvider v-slot="{ errors }" name="Name" rules="required">
                  <v-text-field
                    outlined
                    v-model="model.name"
                    :error="!!errors.length"
                    :error-messages="errors"
                    hide-details="auto"
                  />
                </ValidationProvider>
              </v-col>
              <v-col v-if="!isFacility" cols="12">
                <custom-label item-field-name="Phone" />
                <phone-validator ref="phoneValidator" :phone.sync="model.phone" />
              </v-col>
              <v-col cols="12" v-if="isAdmin || isCashier">
                <custom-label item-field-name="Password" />
                <ValidationProvider v-slot="{ errors }" name="Password" rules="required">
                  <v-text-field
                    autocomplete="new-password"
                    v-model="model.pw"
                    :error="!!errors.length"
                    :error-messages="errors"
                    hide-details="auto"
                    outlined
                    type="password"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" v-if="isMaster || isFacility">
                <custom-label item-field-name="Servicing Capacity" />
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Servicing Capacity"
                  :rules="{ required: true, min_value: 0 }"
                >
                  <v-text-field
                    outlined
                    v-model="model.servicingCapacity"
                    type="number"
                    :min="0"
                    :error="!!errors.length"
                    :error-messages="errors"
                    hide-details="auto"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" v-if="isFacility">
                <custom-label item-field-name="Booking Capacity" />
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Booking Capacity"
                  :rules="{ required: true, min_value: 0 }"
                >
                  <v-text-field
                    outlined
                    v-model="model.bookingCapacity"
                    type="number"
                    :min="0"
                    :error="!!errors.length"
                    :error-messages="errors"
                    hide-details="auto"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" v-if="isCashier">
                <custom-label item-field-name="Store" />

                <v-autocomplete
                  class="autoCompleted"
                  outlined
                  v-bind="$attrs"
                  :items="storesList"
                  :search-input.sync="search"
                  v-model="storeValue"
                  :disabled="false"
                  :menu-props="{ offsetY: true }"
                  append-icon="mdi-magnify"
                  item-text="name"
                  item-value="_id"
                  multiple
                >
                  <template v-slot:selection="data">
                    <v-chip :key="data.item._id" close @click:close="removeStore(data.item._id)">
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <!--          <v-btn @click="doClose()">Close</v-btn>-->
          <v-btn color="primary" :disabled="!valid" @click="debouncePromptCreate" v-if="isCreate">
            Create
          </v-btn>
          <v-btn color="primary" :disabled="valid" @click="debouncePromptUpdate" v-if="!isCreate">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import api from '@/api';
import ApiError from '@/api/ApiError';
import { createUser } from '@/api/auth/membership';
import { getStores } from '@/api/store';
import editDialogMixin from '@/services/editDialogMixin';
import { cloneDeep } from 'lodash';
import CustomLabel from '../customFormLabel/customLabel';
import { roles } from '@/data/constants';
import VueCountryCode from 'vue-country-code';
import { ValidationProvider, Validator } from 'vee-validate';
import { parsePhoneNumber } from 'libphonenumber-js';
import PhoneValidator from './PhoneValidator.vue';
import { debounce } from 'lodash';
import { createUserWithStore } from '@/api/storeUser';

Validator.extend('validPhone', {
  getMessage: (field, params, data) => {
    return 'Phone number is not valid.';
  },
  validate: (value, [phone, countryCode]) => {
    try {
      const phoneNumber = parsePhoneNumber(phone, countryCode);
      return phoneNumber && phoneNumber?.isValid();
    } catch (error) {
      console.log('🚀 ~ error:', error);
      return false;
    }
  },
});

export default {
  name: 'IdentityEditDialog',
  components: { CustomLabel, PhoneValidator, VueCountryCode, ValidationProvider },
  mixins: [editDialogMixin],
  props: {
    maxWidth: {
      type: String,
      default: '500px',
    },
    roleList: {
      type: Array,
      default: () => [],
    },
    title: { default: 'Profile' },
    role: {
      type: String,
      default: 'toca.client',
    },
    selectedManagement: {},
  },
  data() {
    return {
      selectedCountryCallingCode: '852',
      selectedCountryCode: 'HK',
      phoneValidationErrorMsg: '',
      debouncePromptCreate: debounce(this.promptCreate, 500),
      debouncePromptUpdate: debounce(this.promptUpdate, 500),
      storesList: [],
      search: '',
      storeValue: [],
    };
  },
  computed: {
    crudURL() {
      return `Profiles${this.id ? '/' + this.id : ''}`;
    },
    isMaster() {
      return this.role === roles.master && this.selectedManagement !== 'facility';
    },
    isFacility() {
      return this.role === roles.master && this.selectedManagement === 'facility';
    },
    isAdmin() {
      return this.role === roles.admin;
    },
    isCashier() {
      return this.role === roles.cashier;
    },
    phone: {
      get() {
        return this.model.phone
          ? this.model.phone.replace(`+${this.selectedCountryCallingCode}`, '')
          : null;
      },
      set(v) {
        if (v) {
          this.$set(this.model, 'phone', `+${this.selectedCountryCallingCode}` + v);
        }
      },
    },
  },
  watch: {
    valueProxy(v) {
      if (v) {
        this.model = {};
        this.$refs.phoneValidator?.reset();
      }
    },
  },
  async mounted() {
    await this.reload();
    this.prepStores();
  },
  methods: {
    async reload() {
      this.model = {
        isFacility: this.isFacility,
      };
    },
    async prepStores() {
      const storesData = (await getStores(api, { limit: 100 }))?.docs;
      this.storesList = storesData;
    },
    removeStore(id) {
      this.storeValue = this.storeValue.filter(storeId => storeId !== id);
    },
    async promptCreate() {
      if (
        !(await this.$refs.form?.validate?.()) &&
        !(await this.$refs?.phoneValidator?.$refs?.form?.validate?.())
      ) {
        return;
      }
      try {
        let payload = cloneDeep(this.model);
        payload.role = this.role;
        // if (this.role === 'toca.client') {
        //   payload.enabled = false;
        // }
        if (this.isAdmin || this.isMaster || this.isCashier) {
          payload.username = this.model.name;
        }

        if (!this.isCashier) {
          const model = (await createUser(api, payload)).data;
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: 'Created',
            type: 'success',
            color: 'success',
          });
          this.$emit('create', model);
          return model;
        }
        if (this.isCashier) {
          payload.stores = this.storeValue;
          // NOTE: SEPARATE ENDPOINT TO CREATE USE AND CORRESPONDING USER RECORDS
          const model = (await createUserWithStore(api, payload)).data;
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: 'Created',
            type: 'success',
            color: 'success',
          });
          this.$emit('create', model);
          return model;
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    handleError(err) {
      const ae = ApiError.wrap(err);
      // if (ae.code === 'duplicate_key') {
      //   this.$refs.sid.applyResult({
      //     valid: false,
      //     errors: [this.$t('d.sid_was_taken')],
      //   });
      //   return;
      // }
      // alert(ae.message);
      this.$store.dispatch('alert/updateAlertMessage', {
        msg: ae.message,
      });
    },
    onSelectCountryCode({ name, iso2, dialCode }) {
      if (this.model.phone) {
        this.model.phone = this.model.phone?.replace(this.selectedCountryCallingCode, dialCode);
      }
      this.selectedCountryCode = iso2;
      this.selectedCountryCallingCode = dialCode;
    },
  },
};
</script>

<style lang="scss" scoped>
.phone-input {
  ::v-deep .v-input__prepend-outer {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
</style>
