var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ListView',{attrs:{"title":"Transfer Problem","search":_vm.search,"data":_vm.data,"header":_vm.selectedTab ? _vm.header : _vm.confirmedTabHeader,"enableCreate":false,"page":_vm.page,"loading":_vm.isLoading},on:{"reload":_vm.reload,"create":_vm.actionCreate,"navDetails":function () { return false; },"update:search":function($event){_vm.search=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"searchBox",fn:function(){return [_c('div',{staticClass:"d-flex",staticStyle:{"gap":"10px"}},[_c('div',{staticClass:"d-flex align-center selectListCol"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"min-width":"max-content"},attrs:{"label":"From","prepend-icon":"mdi-calendar","outlined":"","readonly":""},model:{value:(_vm.selectedStart),callback:function ($$v) {_vm.selectedStart=$$v},expression:"selectedStart"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startTimeDialog),callback:function ($$v) {_vm.startTimeDialog=$$v},expression:"startTimeDialog"}},[_c('v-date-picker',{attrs:{"header-color":"primary"},on:{"input":function($event){_vm.startTimeDialog = false}},model:{value:(_vm.selectedStart),callback:function ($$v) {_vm.selectedStart=$$v},expression:"selectedStart"}})],1)],1),_c('div',{staticClass:"d-flex align-center selectListCol"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"max-width":"max-content"},attrs:{"label":"To","prepend-icon":"mdi-calendar","readonly":"","outlined":""},model:{value:(_vm.selectedEnd),callback:function ($$v) {_vm.selectedEnd=$$v},expression:"selectedEnd"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endTimeDialog),callback:function ($$v) {_vm.endTimeDialog=$$v},expression:"endTimeDialog"}},[_c('v-date-picker',{attrs:{"header-color":"primary"},on:{"input":function($event){_vm.endTimeDialog = false}},model:{value:(_vm.selectedEnd),callback:function ($$v) {_vm.selectedEnd=$$v},expression:"selectedEnd"}})],1)],1),(_vm.selectedTab)?[_c('v-select',{staticStyle:{"max-width":"300px"},attrs:{"items":_vm.warehouseList,"item-text":"name","item-value":"_id","label":"Sent Warehouse","outlined":"","clearable":""},model:{value:(_vm.filterSentWarehouse),callback:function ($$v) {_vm.filterSentWarehouse=$$v},expression:"filterSentWarehouse"}}),_c('v-select',{staticStyle:{"max-width":"300px"},attrs:{"items":_vm.warehouseList,"item-text":"name","item-value":"_id","label":"Received Warehouse","outlined":"","clearable":""},model:{value:(_vm.filterReceivedWarehouse),callback:function ($$v) {_vm.filterReceivedWarehouse=$$v},expression:"filterReceivedWarehouse"}})]:_vm._e()],2)]},proxy:true},{key:"filter",fn:function(){return [_c('div',{staticClass:"d-flex",staticStyle:{"border-bottom":"1px solid #e0e0e0"}},[_c('v-btn-toggle',{attrs:{"color":"primary","tile":""},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.tabs),function(tab){return _c('v-btn',{key:("tab_" + (tab.value)),attrs:{"value":tab.value}},[_vm._v(_vm._s(tab.text)+" ")])}),1)],1)]},proxy:true},{key:"item.product.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseLocalizedString(item.product.name)['zh-hk'])+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.updatedAt))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.get(item, 'needToFixQtyDifference', false))?_c('v-btn',{staticClass:"text-bold",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.handleOnClick(item._id)}}},[_vm._v(" Adjust ")]):_vm._e()]}},{key:"item.metadata.TransferProblemConfirmedAction",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("translateOptions")(_vm.get(item, 'metadata.TransferProblemConfirmedAction', '-'),_vm.TransferProblemConfirmedActionOptions))+" ")]}},{key:"item.addedTo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.computeAddedLostStockTo(item) || '-')+" ")]}}])}),_c('v-dialog',{attrs:{"width":"600px"},model:{value:(_vm.adjustDialogRef),callback:function ($$v) {_vm.adjustDialogRef=$$v},expression:"adjustDialogRef"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Add Lost Stock to Warehouse")]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('v-form',[_c('v-card-text',[_c('custom-label',{attrs:{"item-field-name":"Reason"}}),_c('ValidationProvider',{attrs:{"name":"Reason","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"outlined":"","items":_vm.productActivityLogUpdateReasonOptions,"error":!!errors.length,"error-messages":errors},model:{value:(_vm.updateReason),callback:function ($$v) {_vm.updateReason=$$v},expression:"updateReason"}})]}}],null,true)}),(_vm.updateReason === _vm.ProductActivityLogUpdateReason.Other)?[_c('ValidationProvider',{attrs:{"name":"Other Reason","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Other Reason","outlined":"","error":!!errors.length,"error-messages":errors},model:{value:(_vm.remarks),callback:function ($$v) {_vm.remarks=$$v},expression:"remarks"}})]}}],null,true)})]:_vm._e(),_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.updateStockWithDifferences('from', _vm.TransferProblemConfirmedAction.AddedToSender)}}},[_vm._v(" Sender ")]),_c('v-btn',{attrs:{"width":"fit-content","color":"primary"},on:{"click":function($event){return _vm.markConfirmed()}}},[_vm._v(" Mark Confirmed Only ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.updateStockWithDifferences('to', _vm.TransferProblemConfirmedAction.AddedToReceiver)}}},[_vm._v(" Receiver ")])],1)],2)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }