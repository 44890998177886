var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"60%","max-width":"100vw"},on:{"create":_vm.reload,"update":_vm.reload,"delete":_vm.reload},model:{value:(_vm.valueProxy),callback:function ($$v) {_vm.valueProxy=$$v},expression:"valueProxy"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{attrs:{"min-height":"250"}},[_c('v-card-title',[_c('span',{staticClass:"mainTitle",staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.title || 'Product Category')+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.valueProxy = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Product Name"}}),_c('ValidationProvider',{attrs:{"name":"Product Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Product Name","error-messages":errors},model:{value:(_vm.model.title),callback:function ($$v) {_vm.$set(_vm.model, "title", $$v)},expression:"model.title"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Description"}}),_c('ValidationProvider',{attrs:{"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","label":"Description","error-messages":errors},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Discount"}}),_c('ValidationProvider',{attrs:{"name":"Discount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","type":"number","label":"Discount","placeholder":"should be larger than 0, smaller than 1, eg. 0.8 (20% off)","error-messages":errors},model:{value:(_vm.model.discount),callback:function ($$v) {_vm.$set(_vm.model, "discount", _vm._n($$v))},expression:"model.discount"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Featured Image"}}),_c('ValidationProvider',{attrs:{"name":"Featured Image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"outlined":"","label":"Image","error-messages":errors,"accept":"image/jpeg,image/png"},model:{value:(_vm.model.featuredImage),callback:function ($$v) {_vm.$set(_vm.model, "featuredImage", $$v)},expression:"model.featuredImage"}})]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){return _vm.doClose()}}},[_vm._v("Close")]),(_vm.isCreate)?_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.debouncePromptCreate}},[_vm._v(" Create ")]):_vm._e(),(!_vm.isCreate)?_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.debouncePromptUpdate}},[_vm._v(" Update ")]):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }