var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListView',{attrs:{"title":_vm.title,"enable-create":!_vm.isMaster,"search":_vm.search,"data":_vm.data,"limit":_vm.limit,"page":_vm.page,"header":_vm.computedHeader,"loading":_vm.isLoading},on:{"reload":_vm.reload,"create":_vm.actionCreate,"navDetails":_vm.actionDetails,"update:search":function($event){_vm.search=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"searchBox",fn:function(ref){
var ctx = ref.ctx;
return [_c('v-text-field',{attrs:{"solo":"","filled":"","prepend-inner-icon":"mdi-magnify"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{on:{"click":function () { return (_vm.qrcodeDialog = true); }}},[_vm._v("mdi-qrcode-scan")])]},proxy:true}],null,true),model:{value:(ctx.searchVal),callback:function ($$v) {_vm.$set(ctx, "searchVal", $$v)},expression:"ctx.searchVal"}})]}},(_vm.selectedRole === 'toca.cashier')?{key:"filterRight",fn:function(){return [_c('div',{staticStyle:{"width":"200px"}},[_c('v-select',{attrs:{"items":_vm.profileStatuses,"item-value":"value","item-text":"text","clearable":"","flat":"","label":"Status","outlined":""},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}})],1)]},proxy:true}:null,{key:"card",fn:function(ref){
var cardContent = ref.cardContent;
return [_c('Card',{staticClass:"clickable",attrs:{"content":cardContent},nativeOn:{"click":function($event){return _vm.actionDetails(cardContent)}}})]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayPhone(item.phone) || '-')+" ")]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.enabled ? 'Active' : 'Inactive')+" ")]}},{key:"item._VIP_activated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseAndLocalize")(_vm.get(item, '_VIP_activated.VIPProduct.name', '-')))+" ")]}}],null,true)},[_c('IdentityEditDialog',{attrs:{"id":_vm.editId,"title":_vm.title,"role":_vm.selectedRole,"selectedManagement":_vm.selectedManagement},on:{"create":_vm.reload,"update":_vm.reload,"delete":_vm.reload},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}}),_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.qrcodeDialog),callback:function ($$v) {_vm.qrcodeDialog=$$v},expression:"qrcodeDialog"}},[_c('qrcode-stream',{on:{"decode":_vm.onDecode,"init":_vm.onInit}},[_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function () { return (_vm.qrcodeDialog = false); }}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }