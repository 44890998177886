<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col cols="6" lg="4">
          <div style="display: flex; justify-content: space-between; width: 100%">
            <div class="mainTitle">{{ model.name | capitalize }} (SID: {{ model.sid }})</div>
          </div>
          <div v-if="model.role" class="font-size-14 fullWidth">
            <div class="itemFieldName d-inline-block" style="min-width: 40px">Role:</div>
            {{ model.role | translateOptions(roleOptions) }}
          </div>
          <div v-if="model.store && isAdmin && !isClient" class="font-size-14 fullWidth">
            <div class="itemFieldName d-inline-block" style="min-width: 40px">Stores:</div>
            {{ computedStores }}
          </div>
          <div v-if="model.user && model.user.username" class="font-size-14 fullWidth">
            <div class="itemFieldName d-inline-block" style="min-width: 40px">
              User Name (LoginID):
            </div>
            {{ model.user.username }}
          </div>
          <div class="font-size-14 fullWidth" v-if="model.team && model.team.label">
            <div class="itemFieldName d-inline-block" style="min-width: 40px">Team:</div>
            {{ model.team && model.team.label }}
          </div>
          <!-- <div v-if="isAdmin && !isClient" class="d-flex">
            <custom-label item-field-name="Position" />
            <v-select
              v-model="MastersPosition"
              :items="positionList"
              outlined
              class="ml-3"
              style="max-width: 330px"
              @input="
                v => {
                  patchMastersPosition(v);
                }
              "
            />
          </div> -->
          <div v-if="isClient" class="d-flex flex-column itemFieldName mb-2" style="gap: 10px">
            Blacklist:
            <v-switch
              v-model="model.isBlocked"
              class="mt-0 pt-0 ml-3"
              hide-details
              @change="v => confirmBlacklist(v)"
            />
          </div>
          <div v-if="isClient" class="d-flex flex-column itemFieldName mb-2">
            Old Customer:
            <div>
              <v-simple-checkbox
                :ripple="false"
                :value="oldCustomer"
                class="old-customer"
                @input="
                  v => {
                    patchOldCustomer(v);
                  }
                "
              />
            </div>
          </div>
          <v-divider
            style="border-color: rgba(0, 0, 0, 0.1)"
            v-if="$vuetify.breakpoint.mdAndDown && isClient"
          />
        </v-col>
        <v-col cols="6" lg="4">
          <div v-if="isCashierProfile" class="d-flex align-center">
            <custom-label item-field-name="Status" />
            <v-switch
              v-model="model.enabled"
              :label="model.enabled ? 'Active' : 'Inactive'"
              class="ml-2"
            ></v-switch>
          </div>
          <template>
            <div v-if="image" class="d-flex py-4">
              <v-img
                :src="image"
                contain
                max-height="180"
                max-width="180"
                @click="openImage"
                style="cursor: pointer"
              />
              <v-btn icon @click="removeImage">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </div>
            <!-- <div v-if="!image" class="d-flex">
              <v-file-input
                v-model="newImage"
                class="flex-grow-1"
                flat
                label="Photo Upload"
                outlined
                accept="image/jpeg,image/png"
              />
              <v-btn
                :disabled="!newImage"
                class="ml-3 flex-shrink-0"
                color="success"
                @click="saveImage"
                >Save
              </v-btn>
            </div> -->
            <v-divider
              style="border-color: rgba(0, 0, 0, 0.1)"
              v-if="$vuetify.breakpoint.mdAndDown && isClient"
            />
          </template>
          <div class="font-size-14 flex fullWidth mb-3">
            <v-btn class="my-3 mx-3" min-height="56" @click="goToChangeUserNamePage"
              >Change User Name</v-btn
            >
            <v-btn class="my-3 mx-3" min-height="56" @click="goToChangePasswordPage"
              >Change Password &nbsp;
            </v-btn>
          </div>
        </v-col>
        <v-col class="d-flex"> </v-col>
      </v-row>
    </v-card-title>
    <v-divider class="mt-3" style="border-color: rgba(0, 0, 0, 0.1)" />
    <v-card-text>
      <ValidationObserver ref="form" v-slot="{ invalid }">
        <v-form ref="form">
          <div class="d-flex mb-4">
            <v-spacer />
            <v-btn :disabled="invalid" color="primary" @click="debounceSave">Save</v-btn>
          </div>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="6" lg="4">
                  <ValidationProvider v-slot="{ errors }" :rules="{ required: true }">
                    <label-with-text-field
                      v-model="model.sid"
                      :error="!!errors.length"
                      :error-messages="errors"
                      item-field-name="SID"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" lg="4">
                  <ValidationProvider v-slot="{ errors }" name="Name" rules="required">
                    <label-with-text-field
                      v-model="model.name"
                      :error="!!errors.length"
                      :error-messages="errors"
                      :required="true"
                      :rules="rules.nameRules"
                      item-field-name="Display Name"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" lg="4">
                  <ValidationProvider v-slot="{ errors }" name="Email">
                    <label-with-text-field
                      v-model="model.email"
                      :error="!!errors.length"
                      :error-messages="errors"
                      item-field-name="Email"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="8">
                  <custom-label item-field-name="Phone" />
                  <phone-validator ref="phoneValidator" :phone.sync="model.phone" />
                </v-col>
                <v-col cols="8">
                  <ValidationProvider v-slot="{ errors }" name="Address">
                    <custom-label itemFieldName="Address"></custom-label>
                    <v-textarea
                      v-model="model.address"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" v-if="isTherapistProfile">
              <v-row>
                <v-col cols="6" lg="4">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <label-with-text-field item-field-name="Employment date">
                        <v-text-field
                          outlined
                          v-model="pickerDateEmployment"
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </label-with-text-field>
                    </template>
                    <v-date-picker v-model="pickerDateEmployment" />
                  </v-menu>
                </v-col>
                <v-col cols="6" lg="4">
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <label-with-text-field item-field-name="Termination date">
                        <v-text-field
                          outlined
                          v-model="pickerDateTermination"
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </label-with-text-field>
                    </template>
                    <v-date-picker v-model="pickerDateTermination" />
                  </v-menu>
                </v-col>
                <v-col cols="6" lg="4">
                  <label-with-text-field item-field-name="Status">
                    <v-select
                      v-model="model.status"
                      :items="profileStatuses"
                      outlined
                      :disabled="!isAdmin"
                    />
                  </label-with-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" lg="4">
                  <label-with-text-field
                    v-model.number="model.servicingCapacity"
                    :disabled="!isAdmin"
                    type="number"
                    @keypress="restrictChars($event)"
                    item-field-name="Servicing Capacity"
                  />
                </v-col>
                <v-col cols="6" lg="4">
                  <label-with-text-field
                    v-model.number="model.bnAutoAssignPriority"
                    :disabled="!isAdmin"
                    type="number"
                    @keypress="restrictChars($event)"
                    item-field-name="Auto Assign Priority"
                  />
                </v-col>
                <v-col cols="6" lg="4">
                  <label-with-text-field
                    v-model.number="model.bnDisplayOrder"
                    :disabled="!isAdmin"
                    type="number"
                    @keypress="restrictChars($event)"
                    item-field-name="Timetable Display Order"
                  />
                </v-col>
                <v-col cols="6" lg="4">
                  <label-with-text-field v-model="model.remarks" item-field-name="Remarks" />
                </v-col>
              </v-row>
            </v-col>
            <!-- <v-col cols="6" lg="4" v-if="isClient">
              <label-with-text-field item-field-name="How Do You Know Us">
                <v-select
                  v-model="model.howToKnowBN"
                  :items="howToKnowBN"
                  outlined
                  @change="model.howToKnowBN !== `other` ? (model.otherWayToKnowBN = null) : null"
                >
                  <template v-slot:selection="{ item }">{{ item | capitalize }}</template>
                  <template v-slot:item="{ item }">{{ item | capitalize }}</template>
                </v-select>
                <v-text-field
                  v-if="get(model, `howToKnowBN`) === 'other'"
                  label="Other"
                  v-model="model.otherWayToKnowBN"
                  hide-details
                  outlined
                  dense
                  style="max-width: 200px"
                />
                <template v-if="get(model, `howToKnowBN`) === 'referred by'">
                  <v-text-field
                    label="Name"
                    v-model="model.howToKnowBNName"
                    hide-details
                    outlined
                    dense
                    style="max-width: 200px"
                  />
                  <v-text-field
                    class="mt-3"
                    label="Phone Number"
                    v-model="model.howToKnowBNPhone"
                    hide-details
                    outlined
                    dense
                    style="max-width: 200px"
                  />
                </template>
              </label-with-text-field>
            </v-col> -->
            <!-- <v-col cols="6" lg="4" v-if="isClient">
              <label-with-text-field item-field-name="Home">
                <v-select v-model="model.homeDistrict" :items="districts" outlined />
              </label-with-text-field>
            </v-col>
            <v-col cols="6" lg="4" v-if="isClient">
              <label-with-text-field item-field-name="Office">
                <v-select v-model="model.officeDistrict" :items="districts" outlined />
              </label-with-text-field>
            </v-col> -->

            <!-- <v-col cols="6" lg="4" v-if="isClient">
              <custom-label itemFieldName="Credit">
                <template #append>
                  <div class="my-4">{{ model.creditBalance }}</div>
                </template>
              </custom-label>
            </v-col>
            <v-col cols="6" lg="4" v-if="isClient">
              <custom-label itemFieldName="Last treatment date">
                <template #append>
                  <div class="my-4" v-if="model._lastBooking">x
                    {{ model._lastBooking.time.start | calendarDate }}
                  </div>
                  <div class="my-4" v-else>-</div>
                </template>
              </custom-label>
            </v-col> -->

            <v-col v-if="isClient" cols="6" lg="4">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <label-with-text-field item-field-name="Member Since">
                    <v-text-field
                      outlined
                      v-model="pickerDate"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </label-with-text-field>
                </template>
                <v-date-picker v-model="pickerDate" />
              </v-menu>
              <!--                  <label-with-text-field-->
              <!--                    v-model="model.created_at"-->
              <!--                    item-field-name="Member Since"-->
              <!--                  />-->
            </v-col>
            <!-- <v-col cols="12" lg="12" v-if="isClient">
              <custom-label item-field-name="Previous Hardcopy" />
              <v-file-input
                v-model="previousHardCopy"
                label="files"
                multiple
                outlined
                accept="image/jpeg,image/png"
              >
                <template v-if="previousHardCopy.length" #append>
                  <v-btn color="primary" small @click="uploadPreviousHardCopy">Upload</v-btn>
                </template>
              </v-file-input>
              <div class="d-flex flex-wrap">
                <template v-for="att of previousHardCopy1">
                  <v-sheet :key="att._id" class="px-3 py-1">
                    <a :href="attnDownloadHref(att)" target="_blank">
                      {{ att.filename }} ({{ att.length | byte }})
                    </a>
                    <v-btn icon @click="removePreviousHardCopy(att)">
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                    <v-img :src="attnThumbHref(att, 128)" max-height="128px" max-width="128px" />
                  </v-sheet>
                </template>
              </div>
            </v-col> -->
            <v-col cols="12" v-if="isTherapistProfile || isAdminProfile || isCashierProfile">
              <div style="display: flex; justify-content: space-between; width: 100%">
                <div class="mainTitle">Emergency Contacts</div>
              </div>
              <v-row>
                <v-col cols="6" lg="4">
                  <ValidationProvider v-slot="{ errors }" name="Name">
                    <label-with-text-field
                      v-model="EmergencyContactsName"
                      :error="!!errors.length"
                      :error-messages="errors"
                      item-field-name="Name"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" lg="4">
                  <ValidationProvider v-slot="{ errors }" name="Phone" :rules="{ digits: 8 }">
                    <label-with-text-field
                      v-model="EmergencyContactsPhone"
                      :error="!!errors.length"
                      :error-messages="errors"
                      item-field-name="Phone"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" lg="4">
                  <ValidationProvider v-slot="{ errors }" name="Relationship">
                    <label-with-text-field
                      v-model="EmergencyContactsRelationship"
                      :error="!!errors.length"
                      :error-messages="errors"
                      item-field-name="Relationship"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
    </v-card-text>
    <!-- <template v-if="isClient">
      <v-divider style="border-color: rgba(0, 0, 0, 0.05)" />
      <v-card-text>
        <custom-label class="pt-3" item-field-name="Customer Labels" />
        <div class="d-flex align-start flex-wrap">
          <v-chip
            v-for="(item, index) in userLabels"
            :key="index"
            class="mr-5 mb-5"
            color="#96734B"
            label
            outlined
            style="font-weight: bold"
          >
            {{ item }}
          </v-chip>
          <v-icon color="green" @click="remarksPopup = true"> mdi-plus-circle-outline</v-icon>
          <remarksDialog
            v-model="remarksPopup"
            :user-labels="userLabels"
            @saveLabel="x => saveCustomerLabels(x)"
          />
        </div>
      </v-card-text>
    </template> -->
    <v-divider class="mt-3" style="border-color: rgba(0, 0, 0, 0.1)" />
    <v-card-text
      class="d-flex w-100 justify-content-center align-items-center"
      v-if="isCashierProfile"
    >
      <div class="mainTitle">Profile Stores</div>
      <v-spacer />
      <div class="d-flex justify-content-center align-items-center">
        <v-btn color="primary" @click="saveStore"> Save </v-btn>
      </div>
    </v-card-text>

    <v-card-text v-if="isCashierProfile">
      <v-autocomplete
        class="autoCompleted mt-4"
        outlined
        v-bind="$attrs"
        :items="storesList"
        :search-input.sync="search"
        v-model="storeValue"
        :disabled="false"
        :menu-props="{ offsetY: true }"
        append-icon="mdi-magnify"
        item-text="name"
        item-value="_id"
        multiple
      >
        <template v-slot:selection="data">
          <v-chip :key="data.item._id" close @click:close="removeStore(data.item._id)">
            {{ data.item.name }}
          </v-chip>
        </template>
      </v-autocomplete>
    </v-card-text>

    <v-dialog
      v-model="confirmDialog"
      width="60%"
      max-width="600"
      @click:outside="
        () => {
          model.isBlocked = !model.isBlocked;
          confirmDialog = false;
        }
      "
    >
      <v-card>
        <div class="d-flex justify-space-around closeButton">
          <v-card-text class="blacklistText">{{ message }}</v-card-text>
          <v-icon
            @click="
              () => {
                model.isBlocked = !model.isBlocked;
                confirmDialog = false;
              }
            "
            >mdi-close
          </v-icon>
        </div>

        <v-card-actions class="d-flex justify-end">
          <!--                    <v-btn-->
          <!--                      @click="-->
          <!--                        () => {-->
          <!--                          model.isBlocked = !model.isBlocked;-->
          <!--                          confirmDialog = false;-->
          <!--                        }-->
          <!--                      "-->
          <!--                    >-->
          <!--                      Cancel-->
          <!--                    </v-btn>-->
          <v-btn @click="saveBlacklist()"> Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import api from '@/api';
import { deleteAttachment, readAttachment, uploadSingleAttachment } from '@/api/attachment';
import {
  patchProfile,
  patchProfileByMaster,
  patchProfileByCashier,
  ProfileStatus,
  updateClientLabels,
} from '@/api/auth/membership';
import { createLoginToken } from '@/api/auth/sso';
import { crudUpdate } from '@/api/crud';
import labelWithTextField from '@/components/customFormLabel/labelWithTextField';
import { districts, month, roleOptions } from '@/data/optionsConstants';
import { attnThumbHref, attnDownloadHref, calendarDate, capitalize } from '@/services/filters';
import { getId, getOid, oidEquals } from '@/services/utils';
import { get, set } from 'lodash';
import moment from 'moment';
import QrcodeVue from 'qrcode.vue';
import { mapGetters } from 'vuex';
import customLabel from '@/components/customFormLabel/customLabel';
import RemarksDialog from '@/components/Identity/remarksDialog';
import { ValidationProvider } from 'vee-validate';
import PhoneValidator from './PhoneValidator.vue';
import { debounce } from 'lodash';
import { getStores } from '@/api/store';
import { assignUserStores, getMatchedStores } from '@/api/storeUser';

export default {
  components: {
    RemarksDialog,
    labelWithTextField,
    PhoneValidator,
    QrcodeVue,
    customLabel,
    ValidationProvider,
  },
  props: {
    model: {},
    userId: {},
    labels: {},
  },
  data() {
    return {
      menu: false,
      menu1: false,
      menu2: false,
      menu3: false,
      valid: false,
      checkbox: false,
      loginRequired: 0,
      showChangeTeam: false,
      dialog: false,
      temp: false,
      rules: {
        nameRules: [v => !!v || 'Name is required'],
      },
      link: {},
      search: '',
      storesList: [],
      storeValue: [],
      ogStoreValue: [], // track deleted store ids
      qrcode: null,
      newImage: null,
      previousHardCopy: [],
      previousHardCopy1: [],
      confirmDialog: false,
      message: null,
      howToKnowBN: ['referred by', 'magazine', 'facebook', 'benature website', 'youtube', 'other'],
      month,
      remarksPopup: false,
      positionList: [
        { text: 'Customer Service Officer', value: 'customer_service_officer' },
        { text: 'Herbal Hair Color Therapist', value: 'hair_therapist' },
        { text: 'Herbal Hair Color Senior Therapist', value: 'senior_hair_therapist' },
        { text: 'Shop Supervisor', value: 'supervisor' },
        { text: 'Shop Manager', value: 'manager' },
        { text: 'Shop Assistant', value: 'assistant' },
        { text: 'Director', value: 'director' },
        { text: 'Service Provider', value: 'service_provider' },
        { text: 'Other', value: 'other' },
      ],
      debounceSave: debounce(this.save, 500),
    };
  },
  async mounted() {
    // await this.reload();
    this.prepStores();
  },
  computed: {
    ...mapGetters('auth', ['role', 'isMaster', 'isAdmin', 'isCashier']),
    pickerDateEmployment: {
      get() {
        return this.model?.metadata?.employmentDate
          ? moment(this.model.metadata.employmentDate).format('YYYY-MM-DD')
          : null;
      },
      set(v) {
        this.$set(this.model.metadata, 'employmentDate', moment(v).toDate());
        this.menu = false;
      },
    },
    pickerDateTermination: {
      get() {
        return this.model?.metadata?.terminationDate
          ? moment(this.model.metadata.terminationDate).format('YYYY-MM-DD')
          : null;
      },
      set(v) {
        this.$set(this.model.metadata, 'terminationDate', moment(v).toDate());
        this.menu1 = false;
      },
    },
    pickerDate: {
      get() {
        return moment(this.model.created_at).format('YYYY-MM-DD');
      },
      set(v) {
        this.model.created_at = moment(v).toDate();
        this.menu2 = false;
      },
    },
    calendarDate,
    districts: () => districts,
    roleOptions: () => roleOptions,
    image() {
      return this.imageId && attnThumbHref(this.imageId);
    },
    isClient() {
      return this.model.role === 'toca.client';
    },
    isTherapistProfile() {
      // do not mix up the use with 'isMaster' from mapGetters
      return this.model.role === 'bn.master';
    },
    isAdminProfile() {
      return this.model.role === 'admin';
    },
    isCashierProfile() {
      return this.model.role === 'toca.cashier';
    },
    id() {
      return this.model._id;
    },
    toggleHeart: {
      get() {
        return get(this.model, ['metadata', 'heart']);
      },
      set(v) {
        set(this.model, ['metadata', 'heart'], v);
      },
    },
    imageId() {
      return this.model.avatar;
    },
    phone: {
      get() {
        return this.model.phone?.replace('+852', '');
      },
      set(v) {
        this.$set(this.model, 'phone', v && `+852${v}`);
      },
    },

    phone2: {
      get() {
        return this.model.phone2;
      },
      set(v) {
        this.$set(this.model, 'phone2', v);
      },
    },

    oldCustomer: {
      get() {
        return get(this.model, 'oldCustomer');
      },
      set(v) {
        set(this.model, 'oldCustomer', v);
      },
    },
    MastersPosition: {
      get() {
        return get(this.model, ['metadata', 'position']);
      },
      set(v) {
        set(this.model, ['metadata', 'position'], v);
      },
    },
    EmergencyContactsName: {
      get() {
        return get(this.model, ['emergencyContacts', 'name']);
      },
      set(v) {
        set(this.model, ['emergencyContacts', 'name'], v);
      },
    },
    EmergencyContactsPhone: {
      get() {
        return get(this.model, ['emergencyContacts', 'phone']);
      },
      set(v) {
        set(this.model, ['emergencyContacts', 'phone'], v);
      },
    },
    EmergencyContactsRelationship: {
      get() {
        return get(this.model, ['emergencyContacts', 'relationship']);
      },
      set(v) {
        set(this.model, ['emergencyContacts', 'relationship'], v);
      },
    },
    userLabels: {
      get() {
        return get(this.model, 'metadata.labels', []);
      },
      set(v) {
        set(this.model, 'metadata.labels', v);
      },
    },
    profileStatuses() {
      return [
        { text: 'Active', value: ProfileStatus.accepted },
        { text: 'Inactive', value: ProfileStatus.rejected },
      ];
    },
    patchProfileByRole() {
      if (this.isMaster) {
        return patchProfileByMaster;
      } else if (this.isCashier) {
        return patchProfileByCashier;
      }
      return patchProfile;
    },
    crudProfilesPath() {
      return this.isMaster ? 'bn/master/profiles' : 'profiles';
    },
    crudClientProfilesPath() {
      return this.isMaster ? 'bn/master/ClientProfiles' : 'bn/admin/ClientProfiles';
    },
    computedStores() {
      return this.model?.store?.[this.model?._id]?.map(x => x?.name)?.join(', ') || '-';
    },
  },
  watch: {
    model() {
      this.reload();
      this.getProfileStores();
    },
  },
  methods: {
    openImage() {
      window.open(this.image);
    },
    moment,
    attnDownloadHref,
    attnThumbHref,
    get,
    async reload() {
      this.previousHardCopy = [];
      if (this.model?.previousHardCopy?.length) {
        this.previousHardCopy1 = await readAttachment(api, this.model.previousHardCopy, {
          returnArray: 1,
        });
      } else {
        this.previousHardCopy1 = [];
      }
    },
    async prepStores() {
      const storesData = (await getStores(api, { limit: 100 }))?.docs;
      this.storesList = storesData;
    },
    async getProfileStores() {
      try {
        console.log(this.model, 'model data here');
        const fetchedData = await getMatchedStores(api, {
          profileIds: [getOid(this.model)],
        });
        const storeIds = fetchedData?.[getOid(this.model)]?.map(d => d?._id);
        this.storeValue = storeIds;
        this.ogStoreValue = storeIds;
      } catch (error) {
        this.$store.dispatch('alert/updateAlertMessage', {
          msg: error.message,
          type: 'error',
          color: 'error',
        });
      }
    },
    async saveStore() {
      try {
        const userId = this.model?.user?._id;
        const removedStoreIds = this.ogStoreValue.filter(e => !this.storeValue.includes(e));
        const data = await assignUserStores(api, userId, {
          storeIds: this.storeValue,
          removeStoreIds: removedStoreIds,
        });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        return data;
      } catch (error) {
        this.$store.dispatch('alert/updateAlertMessage', {
          msg: error.message,
          type: 'error',
          color: 'error',
        });
      }
    },
    async saveBlacklist() {
      try {
        await this.patchProfileByRole(api, this.model._id, { isBlocked: this.model.isBlocked });
        this.confirmDialog = false;
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Save Success',
          type: 'success',
          color: 'success',
        });
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
        });
      }
    },
    confirmBlacklist(v) {
      //if (this.isMaster) return;
      this.message = v
        ? capitalize(this.model.name) + ' will not be able to book online. Confirm to blacklist?'
        : capitalize(this.model.name) + ' will be able to book online. Confirm to unblacklist?';
      this.confirmDialog = true;
    },
    async createLoginToken() {
      try {
        const token = (await createLoginToken(api, this.id)).token;
        this.qrcode = location.origin + '/go' + '?token=' + token;
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
        });
      }
    },
    async updateHeart() {
      //if (this.isMaster) return;
      this.toggleHeart = !this.toggleHeart;
      await this.patchProfileByRole(api, this.id, { metadata: this.model.metadata });
    },
    async save() {
      try {
        if (
          !(await this.$refs.form?.validate?.()) &&
          !(await this.$refs?.phoneValidator?.$refs?.form?.validate?.())
        ) {
          return;
        }
        if (this.isCashierProfile)
          this.$set(
            this.model,
            'status',
            this.model.enabled ? ProfileStatus.accepted : ProfileStatus.rejected,
          );
        await this.patchProfileByRole(api, this.id, this.model);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        this.$emit('reload');
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
        });
      }
    },
    async removeImage() {
      try {
        await deleteAttachment(api, this.imageId);
        await crudUpdate(api, this.crudProfilesPath, this.id, {
          avatar: null,
        });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        this.$emit('reload');
      } catch (e) {
        console.log(e);
      }
    },
    removeStore(id) {
      this.storeValue = this.storeValue.filter(storeId => storeId !== id);
    },
    async saveImage() {
      try {
        const attachment = await uploadSingleAttachment(api, this.newImage);
        await crudUpdate(api, this.crudProfilesPath, this.id, {
          avatar: attachment._id,
        });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        this.$emit('reload');
      } catch (e) {
        console.log(e);
      }
    },
    async uploadPreviousHardCopy() {
      if (!this.previousHardCopy.length) {
        return;
      }
      const attachments = await Promise.all(
        this.previousHardCopy.map(uploadSingleAttachment.bind(null, api)),
      );
      await crudUpdate(api, this.crudProfilesPath, this.id, {
        previousHardCopy: this.model.previousHardCopy.concat(attachments).map(getId),
      });
      await this.$store.dispatch('alert/updateAlertMessage', {
        msg: 'Uploaded',
        type: 'success',
        color: 'success',
      });
      this.$emit('reload');
    },
    async removePreviousHardCopy(att) {
      await deleteAttachment(api, getId(att));
      await crudUpdate(api, this.crudProfilesPath, this.id, {
        previousHardCopy: this.model.previousHardCopy.filter(x => !oidEquals(att, x)),
      });
      await this.$store.dispatch('alert/updateAlertMessage', {
        msg: 'Deleted',
        type: 'success',
        color: 'success',
      });
      this.$emit('reload');
    },
    async patchOldCustomer(v) {
      //if (this.isMaster) return;
      try {
        const res = await crudUpdate(api, this.crudClientProfilesPath, this.id, {
          oldCustomer: v,
        });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Update Success',
          type: 'success',
          color: 'success',
        });
        this.model.oldCustomer = res.oldCustomer;
        this.$emit('reload');
      } catch (e) {}
    },
    async patchMastersPosition(v) {
      try {
        const res = await crudUpdate(api, 'bn/admin/MasterProfiles', this.id, {
          'metadata.position': v,
        });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Update Success',
          type: 'success',
          color: 'success',
        });
        this.model.metadata.position = res.metadata.position;
        this.$emit('reload');
      } catch (e) {}
    },
    async saveCustomerLabels(item) {
      try {
        await updateClientLabels(api, this.id, item);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Update Success',
          type: 'success',
          color: 'success',
        });
        this.userLabels = item;
        this.remarksPopup = false;
      } catch (e) {}
    },

    async goToChangePasswordPage() {
      if (!this.userId) return alert('cannot get user Id');
      this.$router.push({
        name: 'changePassword',
        params: { id: this.userId },
      });
    },

    async goToChangeUserNamePage() {
      if (!this.userId) return alert('cannot get user Id');
      this.$router.push({
        name: 'changeUserName',
        params: { id: this.userId },
      });
    },
    restrictChars($event) {
      if ($event.charCode === 0 || /\d/.test(String.fromCharCode($event.charCode))) {
        return true;
      } else {
        $event.preventDefault();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fullWidth {
  width: 100%;
}

.blacklistText {
  font-size: 20px;
  //padding-top: 15px !important;
  padding-bottom: 0 !important;
}

.closeButton {
  padding: 8px 16px;
}

.font-size-14 {
  font-size: 14px;
}

.placeholder {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.old-customer {
  margin-left: 7px;
}
</style>
